<template>
  <div>
    <the-advisor-layout-header
      headline="Dashboard / Create a new client account"
    />

    <v-card width="900" class="ml-14 mt-6" outlined>
      <v-card color="transparent" flat>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row no-gutters>
            <v-col cols="3"
              ><v-card-text>
                <v-text-field
                  v-model="profile.name"
                  :rules="nameReqRules"
                  :disabled="profile.pref == 'secondary'"
                  label="Account name"
                  class="subtitle-1 primary--text"
                />
              </v-card-text>
            </v-col>
            <v-col cols="3"
              ><v-card-text
                ><v-select
                  v-model="profile.type"
                  :rules="nameReqRules"
                  :items="typeItems"
                  :disabled="profile.pref == 'secondary'"
                  label="Account type"
                  class="subtitle-1  primary--text"
                ></v-select
              ></v-card-text>
            </v-col>
            <v-col cols="3"> </v-col>
            <v-col cols="3"> </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="3">
              <v-card color="transparent" flat>
                <v-card-text
                  v-if="profile.type != 'Joint' && profile.pref == 'primary'"
                  class="title primary--text mt-n4"
                >
                  Personal Details
                </v-card-text>
                <v-card-text
                  v-else-if="
                    profile.type == 'Joint' && profile.pref == 'primary'
                  "
                  class="title primary--text mt-n4"
                >
                  Personal Details 1
                </v-card-text>
                <v-card-text
                  v-else-if="profile.pref == 'secondary'"
                  class="title primary--text mt-n4"
                >
                  Personal Details 2
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="3"
              ><v-card color="transparent" flat>
                <v-card-text class="title primary--text mt-n4">
                  Contact details
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="3"
              ><v-card color="transparent" flat>
                <v-card-text class="title primary--text mt-n4">
                  Residential address
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="3"
              ><v-card color="transparent" flat>
                <v-card-text class="title primary--text mt-n4">
                  Mailing address
                </v-card-text>
                <v-btn
                  @click="copyResidential"
                  color="success"
                  text
                  small
                  class="subtitle-2 text-capitalize mt-n8"
                >
                  (copy residental address)</v-btn
                >
              </v-card>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="3"
              ><v-card-text>
                <v-combobox
                  v-model="profile.title"
                  :items="titleItems"
                  label="Title"
                  class="mt-n6"
                ></v-combobox>

                <v-text-field
                  v-model="profile.firstName"
                  :rules="nameReqRules"
                  label="First name"
                />
                <v-text-field
                  v-model="profile.familyName"
                  :rules="nameReqRules"
                  label="Family name"
                />
                <template>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    top
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="profile.birthDate"
                        :rules="dateReqRules"
                        label="Date of birth"
                        prepend-icon="mdi-star-outline"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="profile.birthDate"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </template>
                <v-select
                  v-model="profile.gender"
                  :items="genderItems"
                  :rules="nameReqRules"
                  label="Gender"
                  prepend-icon="mdi-gender-male-female"
                ></v-select>
                <v-select
                  v-model="profile.martialStatus"
                  :items="martialStatusItems"
                  label="Martial status"
                ></v-select>
                <v-select
                  v-model="profile.residencyStatus"
                  :items="residencyStatusItems"
                  label="Residency status"
                ></v-select>
              </v-card-text>
            </v-col>
            <v-col cols="3"
              ><v-card-text>
                <v-select
                  v-model="profile.prefContactMethod"
                  :items="prefContactMethodItems"
                  label="Preferred method of contact"
                  prepend-icon="mdi-account-tie-voice"
                  class="mt-n6"
                ></v-select>

                <v-text-field
                  v-model="profile.email"
                  :rules="emailReqRules"
                  label="Email"
                  prepend-icon="mdi-email"
                />
                <v-text-field
                  v-model="profile.mobilePhone"
                  :rules="phoneReqRules"
                  label="Mobile phone"
                  prepend-icon="mdi-phone"
                />
                <v-text-field
                  v-model="profile.homePhone"
                  :rules="phoneRules"
                  label="Home phone"
                  prepend-icon="mdi-phone"
                />
                <v-text-field
                  v-model="profile.workPhone"
                  :rules="phoneRules"
                  label="Work phone"
                  prepend-icon="mdi-phone"
                />
                <v-select
                  v-model="profile.prefContactNumber"
                  :items="prefContactNumberItems"
                  label="Preferred contact number"
                  prepend-icon="mdi-card-account-phone"
                ></v-select>
              </v-card-text>
            </v-col>
            <v-col cols="3"
              ><v-card-text
                ><v-text-field
                  v-model="profile.resNumber"
                  :rules="numberRules"
                  label="Number"
                  class="mt-n6"
                />
                <v-text-field
                  v-model="profile.resStreet"
                  label="Street"
                  :rules="nameRules"
                />
                <v-text-field
                  v-model="profile.resSuburb"
                  label="Suburb"
                  :rules="nameRules"
                />
                <v-text-field
                  v-model="profile.resCity"
                  label="Town / City"
                  :rules="nameRules"
                />
                <v-text-field
                  v-model="profile.resPostcode"
                  label="Postcode"
                  :rules="numberRules"
                />
                <v-text-field
                  v-model="profile.resCountry"
                  label="Country"
                  :rules="nameRules"
                />
              </v-card-text>
            </v-col>
            <v-col cols="3"
              ><v-card-text
                ><v-text-field
                  v-model="profile.mailNumber"
                  :rules="numberRules"
                  label="Number"
                  class="mt-n6"
                />
                <v-text-field
                  v-model="profile.mailStreet"
                  label="Street"
                  :rules="nameRules"
                />
                <v-text-field
                  v-model="profile.mailSuburb"
                  label="Suburb"
                  :rules="nameRules"
                />
                <v-text-field
                  v-model="profile.mailCity"
                  label="Town / City"
                  :rules="nameRules"
                />
                <v-text-field
                  v-model="profile.mailPostcode"
                  label="Postcode"
                  :rules="numberRules"
                />
                <v-text-field
                  v-model="profile.mailCountry"
                  label="Country"
                  :rules="nameRules"
                />
              </v-card-text>
            </v-col>
          </v-row>

          <v-progress-linear
            v-if="showProgressBar"
            indeterminate
            color="success"
          ></v-progress-linear>
          <v-card-actions class="mx-auto mt-n12">
            <v-spacer></v-spacer>
            <v-btn text @click="cancel" class="mx-2 mb-1 mt-2">Cancel</v-btn>
            <div v-if="profile.pref == 'primary'">
              <div v-if="(profile.type == 'Individual') | !profile.type">
                <v-btn
                  :disabled="!valid"
                  color="success text--primary"
                  text
                  @click="create"
                  class="mx-2 mb-1 mt-2"
                  >Create account</v-btn
                >
              </div>
              <div v-else-if="profile.type == 'Joint'">
                <v-btn
                  :disabled="!valid"
                  color="success text--primary"
                  text
                  @click="create1st"
                  class="mx-2 mb-1 mt-2"
                  >Add personal details 2</v-btn
                >
              </div>
              <div v-else>
                <v-btn
                  :disabled="!valid"
                  text
                  @click="create1st"
                  class="mx-2 mb-1 mt-2"
                  >Add personal details 2</v-btn
                >
                <v-btn
                  :disabled="!valid"
                  color="success text--primary"
                  text
                  @click="create"
                  class="mx-2 mb-1 mt-2"
                  >Create account</v-btn
                >
              </div>
            </div>
            <div v-else>
              <v-btn
                :disabled="!valid"
                color="success text--primary"
                text
                @click="create"
                class="mx-2 mb-1 mt-2"
                >Create account</v-btn
              >
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import store from '@/vuex/store'
import { mapState } from 'vuex'
import { authComputed } from '@/vuex/helpers.js'
import StaticDataService from '@/services/StaticDataService.js'
import TheAdvisorLayoutHeader from '@/components/TheAdvisorLayoutHeader.vue'

export default {
  name: 'advisorClientNew',

  // Navigation Route Guard to INIT profile data before page is being loaded
  beforeRouteEnter(routeTo, routeFrom, next) {
    store.dispatch('initClientGlobal').then(() => {
      next()
    })
  },

  components: {
    TheAdvisorLayoutHeader
  },

  data() {
    return {
      showProgressBar: false,
      valid: true,
      menu: false,
      typeItems: ['Individual', 'Joint', 'Trust', 'Company', 'Estate'],
      titleItems: ['Mr', 'Mrs', 'Ms', 'Miss', 'Dr'],
      genderItems: StaticDataService.getCategoryGender(),
      prefContactMethodItems: ['Mail', 'Phone', 'Email', 'Falcon9'],
      prefContactNumberItems: ['Mobile phone', 'Work phone', 'Home phone'],
      martialStatusItems: ['single', 'married'],
      residencyStatusItems: ['Resident Visa', 'NZ Resident'],
      nameRules: [
        v =>
          !v || (v && v.length <= 20) || 'Name must be less than 20 characters'
      ],
      nameReqRules: [
        v => !!v || 'Field is required',
        v =>
          !v || (v && v.length <= 20) || 'Name must be less than 20 characters'
      ],
      dateReqRules: [v => !!v || 'Field is required'],
      numberRules: [
        v => !v || /^(?=.*\d).+$/.test(v) || 'Requires numbers only'
      ],
      numberReqRules: [
        v => !!v || 'Number is required',
        v => !v || /^(?=.*\d).+$/.test(v) || 'Requires numbers only'
      ],
      numberRulesCode: [
        v => !!v || 'Code is required',
        v =>
          (/^(?=.*\d).+$/.test(v) && v.length == 6) ||
          'Code must be 6 characters'
      ],
      phoneRules: [
        v => !v || /^[+]+\d+$/.test(v) || 'Use this form ... +64210123456'
      ],
      phoneReqRules: [
        v => !!v || 'Phone is required',
        v => !v || /^[+]+\d+$/.test(v) || 'Use this form ... +64210123456'
      ],
      emailReqRules: [
        v => !!v || 'Email is required',
        v => !v || (!!v && /.+@.+\..+/.test(v)) || 'E-mail must be valid'
      ]
    }
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date)
    },
    copyResidential() {
      this.profile.mailNumber = this.profile.resNumber
      this.profile.mailStreet = this.profile.resStreet
      this.profile.mailSuburb = this.profile.resSuburb
      this.profile.mailCity = this.profile.resCity
      this.profile.mailPostcode = this.profile.resPostcode
      this.profile.mailCountry = this.profile.resCountry
    },
    create() {
      if (this.$refs.form.validate()) {
        this.showProgressBar = true
        this.$store.dispatch('createClient').then(() => {
          this.showProgressBar = false
          this.$router.push('/advisor/dashboard')
        })
      }
    },
    create1st() {
      if (this.$refs.form.validate()) {
        this.showProgressBar = true
        this.$store.dispatch('createClient1st').then(() => {
          this.$refs.form.resetValidation()
          this.showProgressBar = false
        })
      }
    },
    cancel() {
      this.$store.dispatch('initClientGlobal')
      this.$router.push('/advisor/dashboard')
    }
  },
  computed: {
    profile: {
      get() {
        return this.client.profile
      },
      set(attributes) {
        this.$store.commit('SET_CLIENT_PROFILE', attributes)
      }
    },
    ...mapState(['client']),
    ...authComputed
  }
}
</script>

<style lang="scss" scoped></style>
